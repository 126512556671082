<template>
  <div class="app-list">
    <a-row class="search-form" type="flex" justify="space-between">
      <a-col>
        <a-form-model
          layout="inline"
          :model="searchForm"
          @submit="handleSubmit"
          @submit.native.prevent
        >
          <a-form-model-item label="关键词：">
            <a-input v-model="searchForm.keyword" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="分类：">
            <a-select
              v-model="searchForm.category"
              placeholder="请选择"
              style="width: 180px"
            >
              <a-select-option
                v-for="(cate, i) in categoryOptions"
                :key="i"
                :value="cate._id"
              >
                {{ cate.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="审核状态：">
            <a-select
              v-model="searchForm.review_status"
              placeholder="请选择"
              style="width: 180px"
            >
              <a-select-option
                v-for="(status, i) in appReviewStatus"
                :key="i"
                :value="status.value"
              >
                {{ status.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" html-type="submit"> 搜索 </a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="default" @click="onResetClick"> 重置 </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col>
        <a-button type="primary" @click="onAddClick">新增应用</a-button>
      </a-col>
    </a-row>
    <div class="table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :rowKey="(row, index) => index"
        @change="onTableChange"
      >
        <template slot="index" slot-scope="text, record, index">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template slot="isAddDirectly" slot-scope="text">
          <a-tag v-if="text" color="#87d068">是</a-tag>
        </template>
        <template slot="ownership" slot-scope="text, record">
          <template v-if="!record.is_add_directly">
            <a-tag v-if="text" color="#87d068">已验证</a-tag>
            <a-tag v-else color="#f50">待验证</a-tag>
          </template>
          <template v-else>-</template>
        </template>
        <template slot="available" slot-scope="text">
          <a-tag v-if="text" color="#87d068">已上架</a-tag>
          <a-tag v-else color="#f50">未上架</a-tag>
        </template>
        <template slot="reviewStatus" slot-scope="text">
          <a-tag
            v-if="text === APP_REVIEW_STATUS.APPROVED.value"
            color="#87d068"
          >
            通过
          </a-tag>
          <a-tag
            v-else-if="text === APP_REVIEW_STATUS.NOT_APPROVED.value"
            color="#f50"
          >
            未通过
          </a-tag>
          <a-tag
            v-else-if="text === APP_REVIEW_STATUS.IN_REVIEW.value"
            color="#2db7f5"
          >
            审核中
          </a-tag>
        </template>
        <template slot="createBy" slot-scope="text, record">
          <template v-if="record.is_add_directly">
            {{ record.create_by_admin && record.create_by_admin.username || '-' }}
          </template>
          <template v-else>
            {{ record.create_by && `@${record.create_by.pi_username}` || '-' }}
          </template>
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            class="action-btn"
            v-if="record.review_status === APP_REVIEW_STATUS.IN_REVIEW.value"
            @click="onReviewClick(record)"
          >
            审核
          </a>
          <!-- 上架按钮 -->
          <a
            class="action-btn"
            v-if="
              record.review_status === APP_REVIEW_STATUS.APPROVED.value &&
              !record.is_available
            "
            @click="onPublishClick(record)"
          >
            上架
          </a>
          <a
            class="action-btn"
            v-if="
              record.review_status === APP_REVIEW_STATUS.APPROVED.value &&
              record.is_available
            "
            @click="onUnpublishClick(record)"
          >
            下架
          </a>
          <a class="action-btn" @click="onDetailClick(record)"> 详情 </a>
        </template>
      </a-table>
    </div>
    <!-- 详情弹窗 -->
    <DetailDrawer ref="detailDrawer" />
    <!-- 审核弹窗 -->
    <ReviewModal ref="reviewModal" @done="onReviewDone" />
    <!-- 新增弹窗 -->
    <AddAppDrawer ref="addAppDrawer" @done="handleSubmit" />
  </div>
</template>

<script>
import { APP_REVIEW_STATUS } from '@/config/const.js';
import dayjs from 'dayjs';

const columns = [
  {
    key: 'index',
    title: '序号',
    scopedSlots: { customRender: 'index' },
  },
  {
    dataIndex: 'app_name',
    title: '应用名称',
  },
  {
    dataIndex: 'url',
    title: '应用链接',
  },
  {
    dataIndex: 'category.name',
    title: '应用分类',
    // customRender: (text) => {
    //   return (Object.values(APP_CATEGORY).find((v) => v.value === text) || {})
    //     .label;
    // },
  },
  {
    dataIndex: 'is_add_directly',
    title: '后台添加',
    scopedSlots: { customRender: 'isAddDirectly' },
  },
  // {
  //   dataIndex: 'ownership_verified',
  //   title: '所有权验证',
  //   scopedSlots: { customRender: 'ownership' },
  // },
  {
    dataIndex: 'is_available',
    title: '上架状态',
    scopedSlots: { customRender: 'available' },
  },
  {
    dataIndex: 'review_status',
    title: '审核状态',
    scopedSlots: { customRender: 'reviewStatus' },
  },
  {
    dataIndex: 'create_by.pi_username',
    title: '提交人',
    scopedSlots: { customRender: 'createBy' },
  },
  {
    dataIndex: 'create_at',
    title: '提交时间',
    customRender: (text) => {
      return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    dataIndex: 'update_by.username',
    title: '更新人',
    // scopedSlots: { customRender: 'createBy' },
  },
  {
    dataIndex: 'update_at',
    title: '更新时间',
    customRender: (text) => {
      return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  name: 'AppList',
  components: {
    DetailDrawer: () => import('@/components/DetailDrawer.vue'),
    ReviewModal: () => import('@/components/ReviewModal.vue'),
    AddAppDrawer: () => import('@/components/AddAppDrawer.vue'),
  },
  data() {
    return {
      APP_REVIEW_STATUS,
      columns,
      searchForm: {
        keyword: '',
        category: undefined,
        review_status: undefined,
      },
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      categoryOptions: [],
    };
  },
  computed: {
    // categoryOptions() {
    //   return Object.values(APP_CATEGORY);
    // },
    appReviewStatus() {
      return Object.values(APP_REVIEW_STATUS);
    },
  },
  mounted() {
    this.getCategoryList();
    this.getList();
  },
  methods: {
    handleSubmit() {
      this.pagination.current = 1;
      this.getList();
    },
    async getList() {
      const { keyword, category, review_status } = this.searchForm;
      const { current, pageSize } = this.pagination;
      this.loading = true;
      const { err, res } = await this.$api.app.list({
        keyword,
        category,
        review_status,
        pageIndex: current - 1,
        pageSize,
      });

      if (!err) {
        this.data = res.result.list;
        this.pagination.total = res.result.total;
      }
      this.loading = false;
    },
    onResetClick() {
      this.$data.searchForm = this.$options.data().searchForm;
      this.$data.pagination = this.$options.data().pagination;
      this.getList();
    },
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getList();
    },
    onDetailClick(record) {
      this.$refs.detailDrawer.open(record);
    },
    onReviewClick(record) {
      this.$refs.reviewModal.open(record);
    },
    // { _id, review_status }
    onReviewDone() {
      // this.data = this.data.map((item) => {
      //   if (item._id === _id) {
      //     item.review_status = review_status;
      //   }
      //   return item;
      // });
      this.getList();
    },
    // 获取应用分类列表
    async getCategoryList() {
      const { err, res } = await this.$api.app.categoryList();
      if (!err) {
        this.categoryOptions = res.result.list;
      }
    },
    // 点击新增按钮
    onAddClick() {
      this.$refs.addAppDrawer.open();
    },
    // 点击上架按钮
    onPublishClick(record) {
      this.$confirm({
        title: '提示',
        content: `确定要上架应用 ${record.app_name} 吗？`,
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          const { err } = await this.$api.app.publish({ _id: record._id });
          if (!err) {
            this.$message.success('上架成功');
            this.getList();
          }
        },
      });
    },
    // 点击下架按钮
    onUnpublishClick(record) {
      this.$confirm({
        title: '提示',
        content: `确定要下架应用 ${record.app_name} 吗？`,
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          const { err } = await this.$api.app.unpublish({ _id: record._id });
          if (!err) {
            this.$message.success('下架成功');
            this.getList();
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .action-btn {
    margin: 0 3px;
  }
}
</style>
